"use client";

import Link from "next/link";
import { FC, Suspense, useEffect } from "react";
import { usePathname, useRouter, useSearchParams } from "next/navigation";

import { useTextInput, useToggle } from "@/hooks";
import { useRedirectIfLoggedIn } from "@auth/client-sdk-react";

import Text from "../../base/Text/Text";
import Input from "../../form/Input/Input";
import Column from "../../layout/Column/Column";
import Spacer from "../../layout/Spacer/Spacer";
import Image from "../../base/Image/Image";

import { MagicLinkLogin } from "./MagicLinkLogin";
import { PasswordLogin } from "./PasswordLogin";

interface ILoginProps {
  redirectAfterLogin: string;
}

export const Login: FC<ILoginProps> = ({ redirectAfterLogin }) => {

  const router = useRouter();
  const searchParams = useSearchParams();
  const pathname = usePathname();
  const postLoginQuery = searchParams.get("postLogin") as string | undefined;

  useRedirectIfLoggedIn(postLoginQuery ?? redirectAfterLogin);

  const [ withPassword, toggleWithPassword ] = useToggle(false);
  const [ disabled, setDisabled ] = useToggle(false);
  const [ email, setEmail ] = useTextInput();

  // Whenever the user switches login method,
  // re-enable the email field
  useEffect(() => {
    setDisabled(false);
  }, [ withPassword, setDisabled ]);

  // Hydrate pre-filled email from querystring
  useEffect(() => {
    const emailParam = searchParams.get("email");
    if (emailParam) {
      setEmail(emailParam);
      void router.replace(
        pathname,
      );
    }
  }, [ router, setEmail ]);

  return (
    <Column
      component={Login.name}
      className="flex items-center justify-center bg-light-grey px-4 md:px-0"
    >
      <Spacer size="md" />
      <div className="h-24 w-40 md:w-72 md:h-48">
        <Image
          alt="KatKin Login"
          image={{
            src: "/images/features/login/login-cat.webp",
            width: 500,
            height: 285,
            alt: "KatKin Login",
          }}
        />
      </div>
      <Text size={{ default: "3xl", md: "4xl" }} tag="h1" display="title" align="center" >
        Welcome Back!
      </Text>
      <Spacer size="md" />
      <Text display="subtitle">
        { "Don't have an account?" }
        { " " }
        <span className="underline">
          <Link href="/sign-up">Join now!</Link>
        </span>
      </Text>
      <Spacer size="lg" />
      <Suspense>
        <Input
          value={email}
          onChange={setEmail}
          disabled={disabled}
          type="email"
          className="md:w-96 w-full"
          name="Email"
          label="Email"
          placeholder="your@email.com"
          optional={true}
        />
      </Suspense>
      <Spacer size="lg" />

      { withPassword && (
        <Suspense>
          <PasswordLogin email={email}/>
        </Suspense>
      ) }
      { !withPassword && (
        <Suspense>
          <MagicLinkLogin
            email={email}
            disabled={disabled}
            setDisabled={setDisabled}/>
        </Suspense>
      ) }
      <Spacer size="lg" />
      <Column direction="row" className="w-full" align="center">
        <div className="border-2 flex-1 h-0 -ml-4" />
        <button className="font-display underline text-sm mx-3" onClick={toggleWithPassword}>
          or log in with { withPassword ? "a magic link" : "your password" }
        </button>
        <div className="border-2 flex-1 h-0 -mr-4" />
      </Column>
      <Spacer size="2xl" />
    </Column>
  );
};
